@import '../../../../theme/theme';

.footerMainContainer {
  max-width: 950px;
  margin: auto 20px 0px 20px;
  background-color: $backgroundColor;
}

.logo_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 80px;
  justify-content: center;
  > button {
    margin-top: 40px;
  }
}

@media screen and (min-width: 480px) {
  .footerMainContainer {
    margin: auto 20px 60px 20px;
  }
  .logo_container {
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 950px) {
  .footerMainContainer {
    margin: auto;
    padding-bottom: 60px;
  }
}

.hr {
  height: 1px;
  background-color: $primaryFontColor;
  width: 100%;
  margin-top: 100px;
}
.footer_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.footer_links_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.footer_links {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 14px;
  color: $primaryFontColor;
  margin-right: 16px;
  margin-top: 22.2px;
}

.footer_button_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.footer_button {
  border: 1px solid $primaryFontColor;
  border-radius: 7px;
  color: $primaryFontColor;
  text-decoration: none;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 16px;
  margin-top: 18.8px;
}

.footer_button > svg {
  width: 22px;
}
.footer_button_container > a:hover {
  cursor: pointer;
  text-decoration: none;
}

.footer_button:hover {
  cursor: pointer;
  text-decoration: none;
}

.selected_language {
  font-family: $primaryFontFamily;
  font-size: 14px;
  margin: 0px !important;
  margin-left: 8px !important;
}

.selected_language_bold {
  font-family: $primaryFontFamily;
  font-size: 14px;
  font-weight: bold;
  margin: 0px !important;
  margin-left: 8px !important;
}

.pfp_logo_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
}
.unep_logo_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 35px;
}
.unep_logo_text {
  font-family: $primaryFontFamily;
  font-size: 9px;
  font-weight: normal;
  letter-spacing: 0.18px;
  color: $primaryFontColor;
  margin-bottom: 10px;
}
.undecade_logo_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}

.footer_social_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social_button {
  text-decoration: none;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 18.8px;
}

.social_button:hover {
  cursor: pointer;
  text-decoration: none;
}

:export {
  primaryFontColor: $primaryFontColor;
}
